<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Tarifas
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Tarifas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row dense>
            <v-col cols="12" sm="12">
              <span class="primary--text">Selecione a Linha:</span>
              <v-autocomplete
                :items="linhas"
                v-model="dados.id_cad_linhas"
                item-text="descricao"
                item-value="id"
                @change="loadRotas(dados.id_cad_linhas)"
                placeholder="Informe a linha"
                class="mt-1 custom-solo-selection"
                solo
              >
                <template slot="selection" slot-scope="{ item }"
                  >{{ item.prefixo }} - {{ item.descricao }}</template
                >
                <template slot="item" slot-scope="{ item }"
                  >{{ item.prefixo }} - {{ item.descricao }}</template
                >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-expansion-panels focusable v-model="isExpand">
                <v-expansion-panel
                  v-for="(item, index) in destinos"
                  :key="index"
                >
                  <v-expansion-panel-header
                    class="text-capitalize"
                    :style="
                      isExpand == index
                        ? 'background:#2196F3;color:white'
                        : 'background:white;color:black'
                    "
                  >
                    De: {{ item.primeiro_indice.toLowerCase() }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group>
                        <template v-for="(data, i, idf) in item.indices">
                          <v-list-item
                            :key="idf"
                            @click="
                              [
                                (it = item),
                                (da = data),
                                (dialog = true),
                                listDataTarifas(
                                  it.primeiro_id,
                                  da.id_localidade,
                                  dados.id_cad_linhas
                                ),
                              ]
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title class="text-capitalize">
                                Para: {{ data.localidade.toLowerCase() }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="i + 1 < item.indices.length"
                            :key="i"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog v-model="dialog" width="700px">
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span class="text-capitalize">
            De:
            {{ it.primeiro_indice ? it.primeiro_indice.toLowerCase() : "" }} |
            Para:
            {{ da.localidade ? da.localidade.toLowerCase() : "" }}
          </span>
          <v-icon @click="dialog = false" dark>mdi-close</v-icon>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          autocomplete="off"
          @submit.prevent
        >
          <v-card-text class="mt-2">
            <v-row align="center">
              <v-col cols="12" sm="6">
                <span class="primary--text">Data Virgência</span>
                <v-text-field
                  v-model="dados.data_vigencia"
                  required
                  v-mask-date.br
                  :rules="fieldRules"
                  placeholder="Informe a data de virgência"
                  class="mt-n1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="primary--text">Tipo de Serviço</span>
                <v-select
                  :items="servicos"
                  v-model="dados.id_tipo_servico"
                  item-text="tipo_servico"
                  item-value="id"
                  :rules="fieldRules"
                  placeholder="Informe o tipo de serviço"
                  class="mt-n1"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="primary--text">Valor</span>
                <v-text-field
                  v-model="dados.valor"
                  required
                  :rules="fieldRules"
                  v-mask-decimal.br="2"
                  placeholder="Informe o valor"
                  class="mt-n1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="primary--text">Sentido</span>
                <v-select
                  :items="[
                    { cod: 0, sentido: 'IDA' },
                    { cod: 1, sentido: 'VOLTA' },
                    { cod: 2, sentido: 'IDA E VOLTA' },
                  ]"
                  item-text="sentido"
                  item-value="cod"
                  required
                  v-model="dados.sentido"
                  placeholder="Informe o sentido"
                  class="mt-1 custom-solo-selection"
                  solo
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-footer color="primary">
            <v-icon
              dark
              @click="
                listDataTarifas(
                  it.primeiro_id,
                  da.id_localidade,
                  dados.id_cad_linhas
                )
              "
              >mdi-update</v-icon
            >
            <div class="flex-grow-1"></div>
            <v-btn
              dark
              depressed
              text
              :disabled="!valid"
              @click="salvarTarifa(it.primeiro_id, da.id_localidade)"
              >Adicionar</v-btn
            >
          </v-footer>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="grid"
              class="elevation-0"
              :loading="gridLoader"
              loading-text="Carregando..."
            >
              <template v-slot:item.data_vigencia="{ item }">
                <span>{{ convertDate(item.data_vigencia) }}</span>
              </template>
              <template v-slot:item.sentido="{ item }">
                <span>{{
                  item.sentido == 0
                    ? "IDA"
                    : item.sentido == 1
                    ? "VOLTA"
                    : "IDA E VOLTA"
                }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  @click="
                    deleteItem(
                      item.id,
                      item.origem,
                      item.destino,
                      item.id_cad_linhas
                    )
                  "
                  color="error"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      isExpand: false,
      dialog: false,
      da: [],
      it: [],
      loading: false,
      gridLoader: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        data_vigencia: "",
        valor: 0,
        id_tipo_servico: 0,
        sentido: 0,
        origem: 0,
        destino: 0,
        id_cad_linhas: 0,
      },
      headers: [
        { text: "Serviço", value: "servico", sortable: false },
        { text: "Valor R$", value: "valor", sortable: false },
        { text: "D. Virgência", value: "data_vigencia", sortable: false },
        { text: "Sentido", value: "sentido", sortable: false },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      linhas: [],
      servicos: [],
      selected: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      items: [],
      grid: [],
      destinos: [],
    };
  },
  created() {
    this.loader("linhas");
    this.loader("tiposservico");
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/tarifas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    setDate(event) {
      this.dados.data_vigencia = event.target.value;
    },
    setValue(event) {
      this.dados.valor = event.target.value;
    },
    setService(event) {
      this.dados.id_tipo_servico = event;
    },
    setSentido(event) {
      this.dados.sentido = event;
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    async listDataTarifas(origem, destino, linha) {
      this.grid = [];
      this.gridLoader = true;
      await this.$http
        .post("/tarifas/lines", [{ origem }, { destino }, { linha }])
        .then((r) => {
          if (!r.data.result) {
            this.grid = r.data;
            this.gridLoader = false;
          } else {
            this.grid = [];
            this.gridLoader = false;
          }
        })
        .catch((e) => {
          this.loaderBar = false;
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    createArray() {
      let destinos = [];
      for (let index = 0; index < this.items.length; index++) {
        destinos.push({
          localidade: this.items[index].localidade,
          id_localidade: this.items[index].id_localidade,
        });
      }
      this.$http
        .post("/tarifas/list", destinos)
        .then((r) => {
          this.destinos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loader(table) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/` + table)
        .then((r) => {
          table == "linhas" ? (this.linhas = r.data) : (this.servicos = r.data);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadRotas(id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/rotas/${id}`)
        .then((r) => {
          this.items = r.data;
          this.createArray();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvarTarifa(origem, destino) {
      this.dados.origem = origem;
      this.dados.destino = destino;
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/tarifas/create", this.dados)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.success) {
            this.message.text = r.data.success;
            this.message.snacshow = true;
            this.message.type = "success";
            this.formClean();
            this.listDataTarifas(
              this.dados.origem,
              this.dados.destino,
              this.dados.id_cad_linhas
            );
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    deleteItem(id, origem, destino, linha) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/tarifas/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.listDataTarifas(origem, destino, linha);
        });
      }
    },
    formClean() {
      // (this.dados.data_vigencia = ""),
      //   (this.dados.valor = 0),
      this.dados.id_tipo_servico = 0;
      // (this.dados.sentido = 0);
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
